import {ArticleFormDetail} from "../../interfaces/form/ArticleFormDetail";
import {Select, TextInput} from "@mantine/core";
import {InputFormDetail} from "../../interfaces/form/InputFormDetail";
import {
    AsciugatricePosizionamento,
    CappeTipologia,
    classiEnergetiche,
    CongelatorePosizionamento,
    CongelatoreTipologia,
    FornoPosizionamento,
    FornoTipologia,
    FrigoriferoPosizionamento,
    FrigoriferoTipologia,
    LavasciugaPosizionamento,
    LavastovigliePosizionamento,
    LavatricePosizionamento,
    LavatriceTipologia,
    LavelloTipologia,
    MicroondePosizionamento,
    PianoCotturaMateriale,
    PianoCotturaPosizionamento,
    PianoCotturaTipologia
} from "./Constants";

// Using a bidimentional array we can define sections
const defaultInputs: InputFormDetail[][] = [
    [
        {
            label: 'Marca',
            type: TextInput
            }
    ],
    [
        {
            label: 'Altezza (cm)',
            formName: 'Altezza',
            type: TextInput
        },
        {
            label: 'Larghezza (cm)',
            formName: 'Larghezza',
            type: TextInput
        },
        {
            label: 'Profondità (cm)',
            formName: 'Profondità',
            type: TextInput
        }
    ],
    [
        {
            label: 'Classe Energetica',
            type: Select,
            options: classiEnergetiche
        }
    ]
]

export const articleList: ArticleFormDetail[] = [
    /* -------------- */
    /* -- LAVAGGIO -- */
    /* -------------- */
    {
        productType: 'AsciugatricI',
        content: [
            ...defaultInputs,
            [
                {
                    label: 'Posizionamento',
                    formName: 'Posizionamento Elettrodomestico',
                    type: Select,
                    options: AsciugatricePosizionamento
                }
            ]
        ]
    },
    {
        productType: 'LavatricI',
        content: [
            ...defaultInputs,
            [
                {
                    label: 'Tipologia Lavatrice',
                    type: Select,
                    options: LavatriceTipologia
                },
                {
                    label: 'Posizionamento',
                    formName: 'Posizionamento Elettrodomestico',
                    type: Select,
                    options: LavatricePosizionamento
                }
            ]
        ]
    },
    {
        productType: 'Lavasciuga',
        content: [
            ...defaultInputs,
            [
                {
                    label: 'Posizionamento',
                    formName: 'Posizionamento Elettrodomestico',
                    type: Select,
                    options: LavasciugaPosizionamento
                },
                {
                    label: 'Capacità di Carico Asciugatura (Kg)',
                    formName: 'Capacità di Carico Asciugatura',
                    type: TextInput,
                },
                {
                    label: 'Capacità di Carico Lavaggio (Kg)',
                    formName: 'Capacità di Carico Lavaggio',
                    type: TextInput,
                }
            ]
        ]
    },
    {
        productType: 'Lavastoviglie',
        content: [
            ...defaultInputs,
            [
                {
                    label: 'Posizionamento',
                    formName: 'Posizionamento Elettrodomestico',
                    type: Select,
                    options: LavastovigliePosizionamento
                },
                {
                    label: 'Numero di Coperti',
                    type: TextInput,
                }
            ]
        ]
    },
    /* ------------------- */
    /* -- CONSERVAZIONE -- */
    /* ------------------- */
    {
        productType: 'Congelatori',
        content: [
            ...defaultInputs,
            [
                {
                    label: 'Tipologia Congelatore',
                    type: Select,
                    options: CongelatoreTipologia
                },
                {
                    label: 'Posizionamento',
                    formName: 'Posizionamento Elettrodomestico',
                    type: Select,
                    options: CongelatorePosizionamento
                }
            ]
        ]
    },
    {
        productType: 'Frigoriferi',
        content: [
            ...defaultInputs,
            [
                {
                    label: 'Tipologia Frigorifero',
                    type: Select,
                    options: FrigoriferoTipologia
                },
                {
                    label: 'Posizionamento',
                    formName: 'Posizionamento Elettrodomestico',
                    type: Select,
                    options: FrigoriferoPosizionamento
                }
            ]
        ]
    },
    /* ----------------------- */
    /* -- COMPONENTI CUCINA -- */
    /* ----------------------- */
    {
        productType: 'Forni',
        content: [
            ...defaultInputs,
            [
                {
                    label: 'Tipologia Forno',
                    type: Select,
                    options: FornoTipologia
                },
                {
                    label: 'Posizionamento',
                    formName: 'Posizionamento Elettrodomestico',
                    type: Select,
                    options: FornoPosizionamento
                }
            ]
        ]
    },
    {
        productType: 'Piani Cottura',
        content: [
            ...defaultInputs,
            [
                {
                    label: 'Tipologia Piano Cottura',
                    type: Select,
                    options: PianoCotturaTipologia
                },
                {
                    label: 'Materiale Piano Cottura',
                    type: Select,
                    options: PianoCotturaMateriale
                },
                {
                    label: 'Posizionamento',
                    formName: 'Posizionamento Elettrodomestico',
                    type: Select,
                    options: PianoCotturaPosizionamento
                },
                {
                    label: 'Numero Fornelli',
                    type: TextInput,
                }
            ]
        ]
    },
    {
        productType: 'Cappe',
        content: [
            ...defaultInputs,
            [
                {
                    label: 'Tipologia Cappe',
                    type: Select,
                    options: CappeTipologia
                }
            ]
        ]
    },
    {
        productType: 'Lavelli',
        content: [
            ...defaultInputs,
            [
                {
                    label: 'Tipologia Lavello',
                    type: Select,
                    options: LavelloTipologia
                }
            ]
        ]
    },
    /* ---------------------------- */
    /* -- ALTRI ELETTRODOMESTICI -- */
    /* ---------------------------- */
    {
        productType: 'Microonde',
        content: [
            ...defaultInputs,
            [
                {
                    label: 'Posizionamento',
                    formName: 'Posizionamento Elettrodomestico',
                    type: Select,
                    options: MicroondePosizionamento
                }
            ]
        ]
    },
    {
        productType: 'Altro',
        content: [
            ...defaultInputs
        ]
    },
]



