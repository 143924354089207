import {ArticleDetails} from "../../../../../../interfaces/response/ArticleDetails";
import warning from "react-redux/es/utils/warning";
import SingleAlert from "./singleAlert/SingleAlert";

interface WarningProps {
    article: ArticleDetails
}

export default function Alerts({article}: WarningProps) {

    return (
        <>
            {article.warnings.map((alert, index) => {
            return <SingleAlert key={index} alert={alert}/>
        })}
        </>
    )
}