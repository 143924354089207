import {TableArticleDetailsRow} from "../../../../interfaces/tables/TableRows";
import {useEffect, useRef, useState} from "react";
import {DataTable, DataTableColumn, DataTableSortStatus} from "mantine-datatable";
import {
    Box,
    Button, Checkbox,
    Combobox,
    Container, Flex,
    Group,
    Image,
    Stack,
    Text,
    TextInput,
    useCombobox,
    useMantineTheme
} from "@mantine/core";
import {MaterialSymbol} from "react-material-symbols";
import classes from "./TableArticles.module.css";
import {ArticleDetails} from "../../../../interfaces/response/ArticleDetails";
import {filter} from "lodash";
import sortBy from "lodash/sortBy";
import {ArticleMappings, getCategoryStringFromCode} from "../../../../utils/ArticleUtils";

interface TableArticlesProps {
    columns: DataTableColumn<TableArticleDetailsRow>[]
    rows: TableArticleDetailsRow[],
    loading: boolean
}

interface TableFilters {
    [label: string]: (row: TableArticleDetailsRow) => boolean
}

interface TableFilterList {
    title: string
    filterList: TableFilters[];
}

const TableArticles = ({columns, rows, loading}: TableArticlesProps) => {

    const theme = useMantineTheme();
    const combobox = useCombobox();
    const [records, setRecords] = useState<TableArticleDetailsRow[]>([]);
    const [filteredRecords, setFilteredRecords] = useState<TableArticleDetailsRow[]>([]);

    const scrollViewportRef = useRef<HTMLDivElement>(null);
    const rowBatchSize = 20;
    const [rowLoading, setRowLoading] = useState<boolean>(false);
    // - The state handler for the filters above the table
    const [activeFilters, setActiveFilters] = useState<TableFilters>({});
    // - The state handler for the search input above the table
    const [search, setSearch] = useState<string>("");
    // - Handles the current sorting status of all the columns
    const [sortStatus, setSortStatus] = useState<DataTableSortStatus<TableArticleDetailsRow>>({
        columnAccessor: 'code',
        direction: 'desc',
    });


    let timeout: ReturnType<typeof setTimeout> | undefined;

    // Handles the load of additional records when the user scrolls to the button (infinite scrolling)
    const loadMoreRecords = () => {
        if (records.length < rows.length) {
            let data = sortBy(rows, sortStatus.columnAccessor) as TableArticleDetailsRow[];
            data = sortStatus.direction === 'desc' ? data.reverse() : data
            const newRecords = applyFilters(data, activeFilters)
                .filter((row, index) => {
                    return row.name.title.toLowerCase().includes(search.toLowerCase())
                        || getCategoryStringFromCode(row.category).toLowerCase().includes(search.toLowerCase())
                        || row.code.toLowerCase().includes(search.toLowerCase())
                        || row.ean.toLowerCase().includes(search.toLowerCase())
                })
                .slice(0, records.length + rowBatchSize)

            if (records.length === newRecords.length) {
                return
            }

            setRowLoading(true);
            timeout = setTimeout(() => {
                setRecords(newRecords)
                setRowLoading(false);
            }, 500);
        }
    };

    const reset = () => {
        setRecords(rows.slice(0, rowBatchSize));
        // Make sure to scroll to top after resetting records
        scrollViewportRef.current?.scrollTo(0, 0);
    };

    // Clear timeout on unmount
    useEffect(() => {
        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [timeout]);


    // Populate records based on filters, sorting and search result
    useEffect(() => {
        setRowLoading(true)
        // Take sorting data
        let data = sortBy(rows, sortStatus.columnAccessor) as TableArticleDetailsRow[];
        data = sortStatus.direction === 'desc' ? data.reverse() : data
        const filteredRecords = applyFilters(data, activeFilters);
        const searchedRecords = filteredRecords.filter((row) => {
            return row.name.title.toLowerCase().includes(search.toLowerCase())
                || getCategoryStringFromCode(row.category).toLowerCase().includes(search.toLowerCase())
                || row.code.toLowerCase().includes(search.toLowerCase())
                || row.ean.toLowerCase().includes(search.toLowerCase())
        })
        const slicedRecords = searchedRecords.slice(0, rowBatchSize)
        setRecords(slicedRecords)
        setFilteredRecords(searchedRecords)
        setRowLoading(false)
    }, [search, sortStatus, rows, activeFilters])

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                if (document.documentElement.scrollHeight > window.innerHeight) {
                    loadMoreRecords()
                }
            }
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        })

        if (scrollViewportRef.current) {
            observer.observe(scrollViewportRef.current)
        }

        return () => {
            if (scrollViewportRef.current) {
                observer.unobserve(scrollViewportRef.current);
            }
        }
    }, [loadMoreRecords]);

    const filters: TableFilters = {
        "Con Avvertimenti": (row) => row.details?.warnings?.length > 0,
        "Grado A": (row) => row.grade === 'A',
        "Grado B": (row) => row.grade === 'B',
        "Disponibili": (row) => parseFloat(row.availability.replace(',','.')) > 0,
        "Non disponibili": (row) => parseFloat(row.availability.replace(',','.')) <= 0
    }

    const applyFilters = (
        data: TableArticleDetailsRow[],
        filters: TableFilters | null) => {

        if (filters == null) {
            return data;
        } else {
            return data.filter(row => {
                return (Object.entries(filters).every(([k, f]) => {
                        return f(row);
                    }
                ))
            })
        }
    }

    const updateCheckboxFilterList = (label: string, checked: boolean) => {
        if (checked) {
            setActiveFilters({
                ...activeFilters,
                [label]: filters[label]
            })
        } else {
            setActiveFilters(prevState => {
                const newState = {...prevState}
                delete newState[label]
                return newState
            })
        }
    }

    const comboboxOptions = Object.entries(filters).map(([k, v]) => {
        const number = rows.filter(row => v(row)).length
        return (
            <Combobox.Option value={k} key={k}>
                <Checkbox label={`${k} (${number})`} onChange={(event) => updateCheckboxFilterList(k, event.currentTarget.checked)}/>
            </Combobox.Option>)
    })

    return (
        <>
            <Group mb={'xl'}>
                <Combobox
                    store={combobox}>
                    <Combobox.Target>
                        <Button // The "Filter" button
                            variant={"outline"}
                            radius={'lg'}
                            color={theme.colors.gray[2]}
                            rightSection={<MaterialSymbol icon={'filter_list'} size={20} color={theme.colors.gray[1]}/>}
                            c={theme.colors.textBlack[1]}
                            onClick={() => combobox.openDropdown()}
                        >
                            Filtra prodotti
                        </Button>
                    </Combobox.Target>
                    <Combobox.Dropdown className={classes.filterDropdown}>
                        <Combobox.Options>
                            {comboboxOptions}
                        </Combobox.Options>
                    </Combobox.Dropdown>
                </Combobox>

                <TextInput
                    placeholder={'Cerca Articoli'}
                    value={search}
                    onChange={(event) => setSearch(event.currentTarget.value)}
                    radius={'lg'}
                    rightSection={<MaterialSymbol icon={'search'} size={20} color={theme.colors.gray[1]}/>}
                    classNames={{
                        root: classes.searchInputTextRoot,
                        input: classes.searchInputTextInput,
                        wrapper: classes.searchInputTextWrapper
                    }}/>
            </Group>


            <Flex justify={"flex-end"}>
                <Text size={'xs'}>Risultati Trovati: {filteredRecords.length}</Text>
            </Flex>
            <DataTable
                records={records}
                columns={columns}
                fetching={loading || rowLoading}
                loaderType={"dots"}
                classNames={{
                    header: classes.tableHeader,
                    table: classes.tableTable
                }}
                sortStatus={sortStatus}
                onSortStatusChange={setSortStatus}

                style={{
                    border: `2px solid ${theme.colors.gray[0]}`,
                    borderRadius: '12px',
                }}

                rowStyle={(row, index) => ({
                    borderTop: `1px solid ${theme.colors.gray[0]}`,
                    borderBottom: `1px solid ${theme.colors.gray[0]}`,
                    borderCollapse: 'collapse'
                })}
            />
            <div ref={scrollViewportRef}/>
        </>
    );
}

export default TableArticles;