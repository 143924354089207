import {Button} from "@mantine/core";

interface AvailabilityRange {
    min: number,
    max: number,
    message: (num: number) => string
    color: string
}

const ranges: AvailabilityRange[] = [
    {
        min: 0,
        max: 0,
        message: num => 'Non disponibile',
        color: 'red'
    },
    {
        min: 1,
        max: 4,
        message: num => `Disponibilità Scarsa: ${num}`,
        color: 'yellow'
    },
    {
        min: 5,
        max: Infinity,
        message: num => `Disponibilità: ${num}`,
        color: 'green'
    },
]
    interface AvailabilityColumnProps {
    availability: string
}


export default function AvailabilityColumn({availability}: AvailabilityColumnProps) {


    const num = parseFloat(availability.replace(',','.'))

    let range: AvailabilityRange = {
        min: 0,
        max: 0,
        color: 'gray',
        message: num1 => "Disponibiltà non presente"
    }
    ranges.forEach(r => {
        if(num >= r.min && num <= r.max) {
            range = r
        }
    })

    return(
        <Button radius={'lg'} color={range.color} size={'xs'} variant={'filled'}>
            {range.message(num)}
        </Button>
    )



}