import {Box, Flex, Stack, Text} from "@mantine/core";
import classes from "../articleInfo/ArticleInfo.module.css";
import FormTextInput from "../../textInput/FormTextInput";
import {useArticleDetailsFormContext} from "../../ModalArticle";
import {articleAttributes} from "../../../../../../utils/ArticleUtils";
import {ArticleDetails} from "../../../../../../interfaces/response/ArticleDetails";

interface OtherDetailsProps {
    article: ArticleDetails
}

export default function OtherDetails({article}: OtherDetailsProps) {
    return (
        <Box className={classes.root}>
            {/*<Stack gap={'sm'}>*/}
            {/*    <Text>Spedizione</Text>*/}
            {/*    <Flex gap={'sm'} wrap={"wrap"}>*/}
            {/*        {Object.entries(articleAttributes)*/}
            {/*            .filter(([_, details]) => {*/}
            {/*                return (details !== undefined*/}
            {/*                    && details.categories !== undefined*/}
            {/*                    && (details.categories.includes(article.headerArticleDetails.category)))*/}
            {/*            }).map(([attribute, details], index) => {*/}
            {/*                const label = ('label' in details && details['label']) ? details['label'] : attribute;*/}
            {/*                const formLabel = ('formLabel' in details && details['formLabel']) ? details['formLabel'] : attribute;*/}
            {/*                const options = details['options'] ?? undefined;*/}
            {/*                return (*/}
            {/*                    <FormTextInput*/}
            {/*                        key={index}*/}
            {/*                        width={"32%"}*/}
            {/*                        label={label}*/}
            {/*                        options={options}*/}
            {/*                        formInfo={{formLabel: formLabel, formContext: useArticleDetailsFormContext}}/>*/}
            {/*                )*/}
            {/*            })}*/}
            {/*    </Flex>*/}
            {/*</Stack>*/}
        </Box>
    )
}