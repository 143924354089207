import {Group, Text, ThemeIcon, UnstyledButton, useMantineColorScheme, useMantineTheme} from "@mantine/core";
import {useLocation, useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import {mobileNavBarActions} from "../../../redux/slices/MobileNavBarSlice";
import React from "react";
import {TablerIconsProps} from "@tabler/icons-react";
import {MaterialSymbol, SymbolCodepoints} from "react-material-symbols";

interface NavBarLinkProps {
    icon: SymbolCodepoints,
    color: string
    label: string
    url: string
}

function NavBarLink({icon, color, label, url}: NavBarLinkProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const theme = useMantineTheme()
    const { colorScheme } = useMantineColorScheme();
    const { hash, pathname, search } = useLocation()

    const selected = pathname === url

    return (
        <UnstyledButton
            style={(theme) => ({
                display: 'block',
                width: '100%',
                padding: theme.spacing.xs,
                margin: 0,
                backgroundColor: selected ? theme.colors.green[3] : 'none',

                borderRight: '4px solid',
                borderColor: selected ? theme.colors.green[2] : 'transparent',
                '&:hover': {
                    backgroundColor: theme.colors.hover[0],
                },
            })}

            onClick={() =>{
                navigate(url)
                dispatch(mobileNavBarActions.switch())
            } }
        >
            <Group ml={'md'}>
                <ThemeIcon variant="transparent" size={'lg'}>
                    <MaterialSymbol icon={icon} size={26} color={selected ? theme.colors.textBlack[4] : theme.colors.textBlack[3]}/>
                </ThemeIcon>
                <Text size="lg" fw={500} c={selected ? theme.colors.textBlack[4] : theme.colors.textBlack[3]}>{label}</Text>
            </Group>
        </UnstyledButton>
    )
}

export default NavBarLink;