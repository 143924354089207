import classes from "./TechnicalDetails.module.css"
import {ArticleDetails} from "../../../../../../interfaces/response/ArticleDetails";
import {Box, Button, Flex, Stack, useMantineTheme} from "@mantine/core";
import FormTextInput from "../../textInput/FormTextInput";
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import React, {useEffect, useState} from "react";
import FormTextEditor from "../../textEditor/FormTextEditor";
import {
    useGetTechnicalDetailsByEANMutation,
    useGetTechnicalDetailsMutation
} from "../../../../../../services/ArticleService";
import {useArticleDetailsForm} from "../../ModalArticle";
import {LoadingState} from "../../../../../../interfaces/LoadingState";


interface TechnicalDetailsProps {
    article: ArticleDetails,
    loading: LoadingState,
    setLoading: (state: LoadingState) => void
}

export default function TechnicalDetails({article, loading, setLoading}: TechnicalDetailsProps) {

    const theme = useMantineTheme()
    

    const initialValue = article.technicalDetails
    const [technicalDetailsInput, setTechnicalDetailsInput] = useState<string>("")
    const [technicalDetailsContent, setTechnicalDetailsContent] = useState<string>(initialValue)
    const [technicalDetails, isLoadingTechnicalDetails] = useGetTechnicalDetailsMutation();


    const searchTechnicalDetails = (input: string) => {
        setLoading({loading: true, completed: false, error: false})
        technicalDetails({url: input})
            .unwrap()
            .then( (resp) => {
                setTechnicalDetailsContent(resp.technicalDetails)
                setLoading({loading: false, completed: true, error: false})
            })
            .catch( () => setLoading({loading: false, completed: false, error: true}))
    }



    // Text Editor properties
    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            Superscript,
            SubScript,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
        ]
    });

    return (
        <Box className={classes.root}>
            <Stack h={"100%"}>
                <Flex justify={"space-between"} gap={"md"} h={"20%"}>
                    <FormTextInput
                        label={"Cerca per URL o EAN"}
                        placeholder={"https://"}
                        value={technicalDetailsInput}
                        setValue={setTechnicalDetailsInput}/>
                    <Flex align={"flex-end"}>
                        <Button
                            onClick={() => searchTechnicalDetails(technicalDetailsInput)}
                            variant={"filled"}
                            radius={"lg"}
                            color={theme.colors.green[2]}
                            c={theme.colors.textBlack[4]}>
                            Cerca
                        </Button>
                    </Flex>
                </Flex>
                <Flex h={"80%"}>
                    <FormTextEditor formName={'Scheda Tecnica'} content={technicalDetailsContent} setContent={setTechnicalDetailsContent}/>
                </Flex>
            </Stack>
        </Box>

    )
}