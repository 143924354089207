import {ArticleDetails} from "../interfaces/response/ArticleDetails";
import {
    AsciugatricePosizionamento,
    CappeTipologia,
    CongelatorePosizionamento,
    CongelatoreTipologia,
    FornoPosizionamento,
    FornoTipologia,
    FrigoriferoPosizionamento,
    FrigoriferoTipologia,
    LavastovigliePosizionamento,
    LavatricePosizionamento,
    LavatriceTipologia,
    LavelloTipologia,
    MicroondePosizionamento,
    PianoCotturaMateriale,
    PianoCotturaPosizionamento,
    PianoCotturaTipologia
} from "../components/inserimentoDati/Constants";


export const brands = [
    "Aeg",
    "Akai",
    "Asko",
    "Beko",
    "Bertazzoni La Germania",
    "Bosch",
    "Cadel",
    "Candy",
    "Daya",
    "Electrolux",
    "Fisher & Paykel",
    "Foster",
    "Gorenje",
    "Grundig",
    "Haier",
    "Hisense",
    "Hoover",
    "Hotpoint Ariston",
    "Indesit",
    "Kenwood",
    "Kitchenaid",
    "Liebherr",
    "LG",
    "Metro",
    "Mitsubishi",
    "Neff",
    "R.G.V.",
    "Rosieres",
    "Samsung",
    "Sharp",
    "Siemens",
    "Smeg",
    "Whirlpool",
    "Zerowatt"
]

export const colors = [
    "Bianco",
    "Argento",
    "Acciaio Inox",
    "Nero",
    "Antracite"
]

export const energyClass = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G"
]

interface SingleArticleMapping {
    name: string,
    color: string
}

interface ArticleMapping {
    [code: string]: SingleArticleMapping
}

export const ArticleMappings: ArticleMapping = {
    'AELETTR': {
        name: 'Altri Elettrodomestici',
        color: 'teal'
    },
    'ASCIUGATRICI': {
        name: 'Asciugatrice',
        color: 'red'
    },
    'CANTINETTE': {
        name: 'Cantinette Vino',
        color: 'pink'
    },
    'CAPPE': {
        name: 'Cappe',
        color: 'grape'
    },
    'CONDIZIONAT': {
        name: 'Condizionatori / Deumidificatori',
        color: 'violet'
    },
    'CONGELATORE': {
        name: 'Congelatori',
        color: 'indigo'
    },
    'CUCINA': {
        name: 'Cucina',
        color: 'blue'
    },
    'FORNI': {
        name: 'Forni',
        color: 'cyan'
    },
    'FRIGORIFERI': {
        name: 'Frigoriferi',
        color: 'green'
    },
    'LAVASCIUGA': {
        name: 'Lavasciuga',
        color: 'lime'
    },
    'LAVASTOVIGLIE': {
        name: 'Lavastoviglie',
        color: 'yellow'
    },
    'LAVATRICI': {
        name: 'Lavatrici',
        color: 'orange'
    },
    'LAVELLI': {
        name: 'Lavelli',
        color: 'teal'
    },
    'MICROONDE': {
        name: 'Microonde',
        color: 'red'
    },
    'MISCELATORI': {
        name: 'Miscelatori',
        color: 'pink'
    },
    'PCOTTGAS': {
        name: 'Piani Cottura a Gas',
        color: 'grape'
    },
    'PCOTTIND': {
        name: 'Piani Cottura a Induzione',
        color: 'violet'
    },
    'PICELETTR': {
        name: 'Piccoli Elettrodomestici',
        color: 'indigo'
    },
    'TELEVISORI': {
        name: 'Televisori',
        color: 'blue'
    },
    'DEFAULT': {
        name: 'Categoria non Disponibile',
        color: 'gray'
    }
}

export const getCategoryStringFromCode = (categoryCode: string): string => {
    let mapping = ArticleMappings[categoryCode.substring(0, categoryCode.length - 3)]
    if (!mapping) {
        mapping = ArticleMappings['DEFAULT']
    }
    return mapping.name;
}


/*
Object: {
Label: {
    Source,
    Included Categories. Default All,
    Options,
    Checkbox
}
 */

interface ArticleAttribute {
    source: string
    categories?: string[]
    options?: string[]
    checkbox?: boolean
    default?: string
    label?: string
    formLabel?: string
    transform?: (input: string) => string
}

const specificCategoryArticleAttributes: { [attribute: string]: ArticleAttribute } =
    {
        "Tipologia Lavatrice": {
            source: "generalArticleDetails.washingMachineType",
            categories: ["LAVATRICI001", "LAVATRICI002"],
            options: LavatriceTipologia
        },
        // LAVATRICE
        "Posizionamento Lavatrice": {
            label: "Posizionamento",
            formLabel: "Posizionamento Elettrodomestico",
            source: "generalArticleDetails.appliancePositioning",
            categories: ["LAVATRICI001", "LAVATRICI002"],
            options: LavatricePosizionamento
        },
        "Programmi di lavaggio": {
            label: "Programmi di Lavaggio",
            formLabel: "Lavatrice - Programmi di Lavaggio",
            source: "generalArticleDetails.washingMachinePrograms",
            categories: ["LAVATRICI001", "LAVATRICI002"]
        },
        // ASICUGATRICE
        "Posizionamento Asciugatrice": {
            label: "Posizionamento",
            formLabel: "Posizionamento Elettrodomestico",
            source: "generalArticleDetails.appliancePositioning",
            categories: ["ASCIUGATRICI001", "ASCIUGATRICI002"],
            options: AsciugatricePosizionamento
        },
        // LAVASCIUGA
        "Posizionamento Lavasciuga": {
            label: "Posizionamento",
            formLabel: "Posizionamento Elettrodomestico",
            source: "generalArticleDetails.appliancePositioning",
            categories: ["LAVASCIUGA001", "LAVASCIUGA002"],
            options: AsciugatricePosizionamento
        },
        "Capacità di Carico Asciugatura": {
            label: "Capacità di Carico Asciugatura (Kg)",
            formLabel: "Capacità di Carico Asciugatura",
            source: "generalArticleDetails.dryingCapacity",
            categories: ["LAVASCIUGA001", "LAVASCIUGA002"]
        },
        "Capacità di Carico Lavaggio": {
            label: "Capacità di Carico Lavaggio (Kg)",
            formLabel: "Capacità di Carico Lavaggio",
            source: "generalArticleDetails.washingLoadCapacity",
            categories: ["LAVASCIUGA001", "LAVASCIUGA002", "LAVATRICI001", "LAVATRICI002"]
        },
        // LAVASTOVIGLIE
        "Posizionamento Lavastoviglie": {
            label: "Posizionamento",
            formLabel: "Posizionamento Elettrodomestico",
            source: "generalArticleDetails.appliancePositioning",
            categories: ["LAVASTOVIGLIE001", "LAVASTOVIGLIE002"],
            options: LavastovigliePosizionamento
        },
        "Numero di Coperti": {
            source: "generalArticleDetails.coverNumber",
            categories: ["LAVASTOVIGLIE001", "LAVASTOVIGLIE002"],
        },
        // CONGELATORI
        "Posizionamento Congelatore": {
            label: "Posizionamento",
            formLabel: "Posizionamento Elettrodomestico",
            source: "generalArticleDetails.appliancePositioning",
            categories: ["CONGELATORE001", "CONGELATORE002"],
            options: CongelatorePosizionamento
        },
        "Tipologia Congelatore": {
            source: "generalArticleDetails.freezerType",
            categories: ["CONGELATORE001", "CONGELATORE002"],
            options: CongelatoreTipologia
        },
        // FRIGORIFERI
        "Posizionamento Frigorifero": {
            label: "Posizionamento",
            formLabel: "Posizionamento Elettrodomestico",
            source: "generalArticleDetails.appliancePositioning",
            categories: ["FRIGORIFERI001", "FRIGORIFERI002"],
            options: FrigoriferoPosizionamento
        },
        "Tipologia Frigorifero": {
            source: "generalArticleDetails.fridgeType",
            categories: ["FRIGORIFERI001", "FRIGORIFERI002"],
            options: FrigoriferoTipologia
        },
        // FORNI
        "Posizionamento Forno": {
            label: "Posizionamento",
            formLabel: "Posizionamento Elettrodomestico",
            source: "generalArticleDetails.appliancePositioning",
            categories: ["FORNI001", "FORNI002"],
            options: FornoPosizionamento
        },
        "Tipologia Forno": {
            source: "generalArticleDetails.ovenType",
            categories: ["FORNI001", "FORNI002"],
            options: FornoTipologia
        },
        // PIANO COTTURA
        "Posizionamento Piano Cottura": {
            label: "Posizionamento",
            formLabel: "Posizionamento Elettrodomestico",
            source: "generalArticleDetails.appliancePositioning",
            categories: ["PCOTTIND001", "PCOTTIND002", "PCOTTGAS001", "PICOTTGAS002"],
            options: PianoCotturaPosizionamento
        },
        "Tipologia Piano Cottura": {
            source: "generalArticleDetails.stoveType",
            categories: ["PCOTTIND001", "PCOTTIND002", "PCOTTGAS001", "PICOTTGAS002"],
            options: PianoCotturaTipologia
        },
        "Materiale Piano Cottura": {
            source: "generalArticleDetails.stoveMaterial",
            categories: ["PCOTTIND001", "PCOTTIND002", "PCOTTGAS001", "PICOTTGAS002"],
            options: PianoCotturaMateriale
        },
        "Numero Fornelli": {
            source: "generalArticleDetails.stoveNumber",
            categories: ["PCOTTIND001", "PCOTTIND002", "PCOTTGAS001", "PICOTTGAS002"],
        },
        // CAPPE
        "Tipologia Cappe": {
            source: "generalArticleDetails.hoodType",
            categories: ["CAPPE001", "CAPPE002"],
            options: CappeTipologia
        },
        // LAVELLO
        "Tipologia Lavello": {
            source: "generalArticleDetails.sinkType",
            categories: ["LAVELLI001", "LAVELLI002"],
            options: LavelloTipologia
        },
        // MICROONDE
        "Posizionamento Microonde": {
            label: "Posizionamento",
            formLabel: "Posizionamento Elettrodomestico",
            source: "generalArticleDetails.appliancePositioning",
            categories: ["MICROONDE001", "MICROONDE002"],
            options: MicroondePosizionamento
        },


    }

export const articleAttributes: { [attribute: string]: ArticleAttribute } =
    {
        "TItolo Articolo ITA": {
            source: "headerArticleDetails.title",
        },
        "codice": {
            source: "headerArticleDetails.code"
        },
        "unitaInStock": {
            source: "headerArticleDetails.availability"
        },
        "categoriaMerceologica": {
            source: "headerArticleDetails.category"
        },
        "codiceAlternativo": {
            source: "headerArticleDetails.ean"
        },
        "imgProdotto": {
            source: "headerArticleDetails.imageLink"
        },
        "Marca": {
            source: "generalArticleDetails.brand",
            options: brands,
            // transform: (str) => str.toUpperCase()
        },
        "Classe Energetica": {
            source: "generalArticleDetails.energyClass",
            options: energyClass
        },
        "Altezza": {
            source: "generalArticleDetails.dimensions.height"
        },
        "Larghezza": {
            source: "generalArticleDetails.dimensions.length"
        },
        "Profondità": {
            source: "generalArticleDetails.dimensions.width"
        },
        "Colore": {
            source: "generalArticleDetails.color",
            options: colors,
        },
        "Rumorosità": {
            source: "generalArticleDetails.noisiness"
        },
        "descrizioneHtml": {
            source: "description"
        },
        "Scheda Tecnica": {
            source: "technicalDetails"
        },
        ...specificCategoryArticleAttributes
        // "FRASE - Istruzioni non disponibili":,
        // "FRASE - Imballo Rovinato",
        // "FRASE - Prodotto Testato",
        // "FRASE - Altri dettagli",
        // "FRASE - Tempo Evasione Ordine",
        // "FRASE MANUALE - Altri dettagli",
        // "FRASE - Garanzia 24 Mesi Ufficiale Italia",
        // "FRASE - Prodotto Mai Usato",
    }


const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, key) => acc && acc[key], obj);
}

/**
 * This function sets a value on an object given the path position
 * @param obj the object to populate
 * @param path the path of the object to set the value to
 * @param value the value to set
 */
export const setNestedValue = (obj: any, path: string, value: any) => {
    const keys = path.split('.');
    const lastKey = keys.pop()!;  // Get the last key, which is where the value will be set

    const target = keys.reduce((acc, key) => {
        // Create an empty object if the key doesn't exist
        if (acc[key] === undefined) {
            acc[key] = {};
        }
        return acc[key];
    }, obj);

    target[lastKey] = value;  // Set the value at the final key
};

/**
 * Get the list of attributes that are specific for a given article.
 * The Article Object contains all possible attributes for all possible categories.
 * This functions returns only the attributes that are specific for a given article
 * @param article
 */
export const mapArticleAttributes = (article: ArticleDetails) => {
    let filteredArticleAttributes: { [attribute: string]: any } = {}

    for (const [attr, details] of Object.entries(articleAttributes)) {
        if (!details.categories || details.categories.includes(article.headerArticleDetails.category)) {
            // If the attribute belongs to the category
            const value = getNestedValue(article, details.source)
            const formLabel = ('formLabel' in details && details['formLabel']) ? details['formLabel'] : attr;
            filteredArticleAttributes[formLabel] = value
        }
    }

    // console.log("FILTERED ATTRIBUTES")
    // console.log(filteredArticleAttributes)

    return filteredArticleAttributes;
}

