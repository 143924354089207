import {Link, RichTextEditor} from '@mantine/tiptap';
import {useEditor} from '@tiptap/react';
import {StarterKit} from "@tiptap/starter-kit";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {ScrollArea, Tabs, Textarea} from "@mantine/core";
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Image from '@tiptap/extension-image'
import {useArticleDetailsFormContext} from "../ModalArticle";
import classes from "./FormTextEditor.module.css"

interface TextEditorProps {
    formName: string;
    editable?: boolean;
    withRichMenu?: boolean;
    content?: string;
    setContent: Dispatch<SetStateAction<string>>
}

const FormTextEditor = (props: TextEditorProps) => {

    const sanitizeString = (s: string) => {
        return s.replace(/"/g, '""');
    }

    const form = useArticleDetailsFormContext();

    const editor = useEditor({
        extensions: [
            StarterKit,
            Link,
            Table.configure({
                resizable: true,
            }),
            TableRow,
            TableHeader,
            TableCell,
            Image.configure({
                inline: true,
                allowBase64: true
            })
        ],

        // Keep content variable so that we can put the html version in the code section
        content: props.content ?? '',
        editable: !!props.editable,
        onUpdate: (e) => {
            props.setContent(e.editor.getHTML())
            form.setFieldValue(props.formName, `"${sanitizeString(e.editor.getHTML())}"`);
        }
    });


    useEffect(()=>{
        editor?.commands.setContent(props.content ?? '')
        if (editor?.getHTML()) {
            form.setFieldValue(props.formName, `"${sanitizeString(editor?.getHTML() ?? "")}"`);
        }
    }, [props.content]);

    return (

        <Tabs
            variant="pills"
            w={"100%"}
            radius = "lg"
            color='dark'
            defaultValue="Preview"
            classNames={{
                list: classes.list,
                panel: classes.panel
            }}>
            <Tabs.List>
                <Tabs.Tab value={"Preview"} pt={"xs"}>
                    Preview
                </Tabs.Tab>
                <Tabs.Tab value={"Codice"} pt={"xs"} >
                    Codice
                </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value={"Preview"} pt={"xs"}>
                <ScrollArea
                    classNames={{
                    root: classes.scrollAreaRoot,
                    viewport: classes.scrollAreaViewPort
                }}>
                <RichTextEditor
                    editor={editor}
                    classNames={{
                        content: classes.content,
                        root: classes.root,
                        typographyStylesProvider: classes.typographyStylesProvider
                    }}>
                            <RichTextEditor.Content />
                </RichTextEditor>
            </ScrollArea>
            </Tabs.Panel>

            <Tabs.Panel value={"Codice"} pt={"xs"}>
                <ScrollArea
                    classNames={{
                        root: classes.scrollAreaRoot,
                        viewport: classes.scrollAreaViewPort
                    }}
                    type={"always"}>
                    <Textarea
                        autosize
                        classNames={{
                            root: classes.textAreaRoot,
                            wrapper: classes.textAreaWrapper,
                            input: classes.textAreaInput
                        }}
                        value={props.content}
                        onChange={(e) => {
                            props.setContent(e.currentTarget.value)
                            form.setFieldValue(props.formName, `"${sanitizeString(e.currentTarget.value)}"`);
                        }}
                    />
                </ScrollArea>

            </Tabs.Panel>
        </Tabs>

    );
}

export default FormTextEditor;