import {Grid, Image} from "@mantine/core";
import LoginForm from "../../components/pageComponents/login/loginform/LoginForm";
import React from "react";
import classes from "./Login.module.css"


function Login() {
    return (
        <Grid
            classNames={{
                root: classes.root,
                inner: classes.inner
            }}>
            <Grid.Col span={{md: 6, lg: 5}} p={'0'}>
                <LoginForm />
            </Grid.Col>
            <Grid.Col style={{'& *': {height: '100%'}, '@media (max-width:800px)': {display: 'none'}}} p={'0'} span={{sm:0, md:6, lg:7}}>
                <Image height={"100%"} fit="cover" src={"/assets/login-image.jpg"} />
            </Grid.Col>
        </Grid>
    )
}

export default Login;