import type {BaseQueryFn, FetchArgs, FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {fetchBaseQuery} from "@reduxjs/toolkit/query";
import {getToken, loginRequest, msalConfig} from "./authConfig";
import {PublicClientApplication} from "@azure/msal-browser";

const backendURL = process.env.REACT_APP_API_URL || 'http://localhost:18080'
const baseQuery = fetchBaseQuery({
    baseUrl: backendURL,
    prepareHeaders: (headers, { getState, endpoint }) => {
        //const token = getState().login_details?.token
        if (endpoint !== "authenticate") {
            headers.set('Authorization', `Bearer ${getToken()}`)
        }
        return headers
    }
})

async function ReAuthenticate() {
    console.log("REFRESH MAN")

    const pca = new PublicClientApplication(msalConfig)
    const account = pca.getAllAccounts()[0]

    const resp = await pca.acquireTokenSilent(
        {...loginRequest, account: account}
    ).then(r => {
        console.log(r)
        console.log(r.idToken)
    }).catch(err => {
        console.log("ERRORE")
        console.log(err)
    })
}


export const BaseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
    async(args, api, extraOptions) => {
        let result = await baseQuery(args,api, extraOptions)
        if (result.error && (result.error.status === 403 || result.error.status == 401)) {
            ReAuthenticate();
        }
        return result;
    }