import {Button, Flex, Modal, ScrollArea, Stack, Tabs, Text, ThemeIcon, useMantineTheme} from "@mantine/core";
import {MaterialSymbol} from "react-material-symbols";
import FormTextInput from "./textInput/FormTextInput";
import {ArticleDetails} from "../../../../interfaces/response/ArticleDetails";
import classes from "./ModalArticle.module.css";
import ArticleDetailsTab from "./tabs/ArticleDetailsTabs";
import {createFormContext} from "@mantine/form";
import {useDetailsForm} from "../../../../pages/main/InserimentoDati";
import {articleAttributes, mapArticleAttributes, setNestedValue} from "../../../../utils/ArticleUtils";
import React, {useEffect, useRef, useState} from "react";
import {CreateCSVRequest} from "../../../../interfaces/request/CreateCSVRequest";
import {toCSV} from "../../../../utils/CSVUtils";
import {LoadingState} from "../../../../interfaces/LoadingState";
import {useUploadArticleDataMutation} from "../../../../services/ArticleService";
import {set, values} from "lodash";
import TableLoadingRow from "../../../TableLoadingRow";
import {useArticles} from "../../../../pages/main/articoli/Articoli";
import {useDispatch} from "react-redux";
import {ArticleModalStatusActions} from "../../../../redux/slices/ArticleModalStatusSlice";


interface ModalArticleProps{
    article: ArticleDetails,
    modalOpened: boolean,
    modalClose: () => void
}
export default function ModalArticle({article, modalOpened, modalClose}: ModalArticleProps) {

    // Theme
    const theme = useMantineTheme()
    const {fetchArticles} = useArticles();
    const dispatch = useDispatch();

    // States
    // - Loading
    const [loadingState, setLoadingState] = useState<LoadingState>({loading: false, completed: false, error: false})

    // APIS
    // - Upload CSV
    const [uploadData, isLoading] = useUploadArticleDataMutation();

    let attributes = useRef({})

    let form = useArticleDetailsForm({
        initialValues: attributes
    })

    useEffect(() => {
        if (modalOpened) {
            attributes.current = mapArticleAttributes(article)
        }
    }, [modalOpened, article]);

    // console.log(article)



    // useEffect(() => {
    //     if (modalOpened) {
    //         attributes.current =
    //         form.setValues(attributes)
    //     }
    // }, [form]);


    // Handler for the EDIT button after data insertion
    const handleEditButton = () => {
        const code = article.headerArticleDetails.code
        const inputsToSend: Record<string, string> = {}
        inputsToSend['codice'] = code

        const articleCopy = JSON.parse(JSON.stringify(article))

        // Get only the fields in the form that have been touched
        Object.entries(form.getValues()).forEach(([k,v],n) => {
            if (form.isDirty(k)) {

                let value = v

                // console.log(`KEY to look for: ${k}`)
                // Find whether the data needs to be transformed
                const attributeDetails = Object.entries(articleAttributes).find(([key,attributeValue]) => {
                    // console.log(`KEY: ${key}, FORM LABEL: ${attributeValue.formLabel}`)
                    return key === k || attributeValue.formLabel === k
                })
                if (attributeDetails) {
                    if (attributeDetails[1].transform) {
                        value = attributeDetails[1].transform(v)
                    }

                    const articleKey = attributeDetails[1].source
                    console.log(articleKey)
                    setNestedValue(articleCopy, articleKey, value)
                }

                inputsToSend[k] = value

            }
        })


        console.log(inputsToSend)
        console.log(articleCopy)

        // Update Article Data with change inputs

        // Create request, transform the form into a CSV
        const request: CreateCSVRequest = {
            content: toCSV(inputsToSend),
            code: code
        }
        console.log("GENERATE CSV")
        console.log(request.content);
        setLoadingState({loading: true, completed: false, error: false})
        uploadData(articleCopy)
            .then( (data) => {
                if ('error' in data) {
                    console.error(data)
                    setLoadingState({loading: false, completed: false, error: true})
                } else {
                    setLoadingState({loading: false, completed: true, error: false})
                    setTimeout(() => fetchArticles(), 500)
                }
            })
            .catch( () => setLoadingState({loading: false, completed: false, error: true}))



    }

    if (!modalOpened) {
        //console.log("Modal should not be opened")
        return <></>
    }

    // console.log("CURRENT ATTRIBUTES")
    // console.log(attributes.current)
    // @ts-ignore
    // @ts-ignore
    return (
        <ArticleDetailsFormProvider form={form}>
            <Modal.Root opened={modalOpened}
                        onClose={() => {
                            //console.log("Closing");
                            dispatch(ArticleModalStatusActions.set(""));
                            modalClose()}}
                        centered size="auto" scrollAreaComponent={ScrollArea.Autosize}>
                <Modal.Overlay/>
                <Modal.Content aria-modal="true">
                    <Modal.Header>
                        <Flex gap={'sm'}>
                            <ThemeIcon variant={'transparent'}>
                                <MaterialSymbol size={36} color={theme.colors.textBlack[0]} icon={'edit_square'}/>
                            </ThemeIcon>
                            <Text classNames={{root: classes.title}}>
                                Modifica Articolo
                            </Text>
                        </Flex>

                        <Modal.CloseButton/>
                    </Modal.Header>
                    <Modal.Body>
                        <Stack justify={"space-between"} className={classes.body}>
                            <Stack h={"100%"}>
                                <Flex className={classes.header}>
                                    <Flex className={classes.imageContainer}>
                                        <img alt={'Immagine Prodotto'} className={classes.image} src={article.headerArticleDetails.imageLink}/>
                                    </Flex>
                                    <Stack className={classes.data}>
                                        <FormTextInput disabled label={"Titolo articolo"} value={article.headerArticleDetails.title} />
                                        <FormTextInput disabled label={"Codice articolo"} value={article.headerArticleDetails.code} />
                                        <FormTextInput disabled label={"EAN"} value={article.headerArticleDetails.ean} />
                                    </Stack>
                                </Flex>

                                <ArticleDetailsTab article={article} loading={loadingState} setLoading={setLoadingState}/>
                            </Stack>
                            <Flex justify={"flex-end"} gap={"md"} align={'center'}>
                                <TableLoadingRow loadingState={loadingState} />
                                <Button onClick={() => {
                                    //console.log("Closing from button");
                                    dispatch(ArticleModalStatusActions.set(""));
                                    modalClose()}} variant={"outline"} radius={"lg"} color={theme.colors.textBlack[4]}>Annulla</Button>
                                <Button onClick={() => handleEditButton()} variant={"filled"} radius={"lg"} color={theme.colors.green[2]} c={theme.colors.textBlack[4]}>Salva Modifiche</Button>
                            </Flex>
                        </Stack>

                    </Modal.Body>
                </Modal.Content>
            </Modal.Root>
        </ArticleDetailsFormProvider>

    )
}

export const [ArticleDetailsFormProvider, useArticleDetailsFormContext, useArticleDetailsForm] = createFormContext<Record<string, any>>();
