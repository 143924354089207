import {DataTable, DataTableColumn, DataTableSortStatus} from "mantine-datatable";
import {useEffect, useState} from "react";
import TableFileName from "./TableFileName";
import {TextInput, useStyles} from "@mantine/core";
import TableStatusText from "./TableStatusText";
import sortBy from 'lodash/sortBy';
import {IconSearch} from '@tabler/icons-react';
import TableListMessages from "./TableListMessages";
import TableLoadingRow from "./TableLoadingRow";
import {LoadingState} from "../interfaces/LoadingState";
import {TableFilesRow} from "../interfaces/TableFilesRow";


interface TableFolderProps {
    columns: DataTableColumn<TableFilesRow>[]
    rows: TableFilesRow[]
    loading: any
    selected?: any[]
    setSelected?: any
}

function TableFolders({columns, rows, loading, selected, setSelected}: TableFolderProps) {



    //const [bodyRef] = useAutoAnimate<HTMLTableSectionElement>();

    const [sortStatus, setSortStatus] = useState<DataTableSortStatus<TableFilesRow>>({ columnAccessor: 'name', direction: 'asc' });
    const [records, setRecords] = useState(sortBy(rows, 'name') as TableFilesRow[]);
    const [search, setSearch] = useState(''); // <-- The SearchBox State

    // Sort Whenever sortStatus Changes
    useEffect(() => {
        const data = sortBy(rows, sortStatus.columnAccessor) as TableFilesRow[];
        setRecords(sortStatus.direction === 'desc' ? data.reverse() as TableFilesRow[] : data);
    }, [sortStatus]);

    useEffect(() => {
        setRecords(sortBy(rows, 'name'))
    }, [rows])

    return (
        <>
            <TextInput
                radius={'lg'}
                style={{paddingBottom: '16px', flexBasis: '60%'}}
                placeholder="Search..."
                leftSection={<IconSearch size={16} />}
                value={search}
                onChange={(e) => setSearch(e.currentTarget.value)}
            />
            <DataTable<TableFilesRow>
                rowStyle={(row, index) => ({
                    backgroundColor: 'rgb(238,238,238)'
                })}
                highlightOnHover
                records={records.filter(r => r.name.includes(search))}
                columns={columns}

                height={loading ? 180 : '100%'}
                selectedRecords={selected as TableFilesRow[]}
                onSelectedRecordsChange={setSelected}

                sortStatus={sortStatus}
                onSortStatusChange={(a) => setSortStatus(a)}

                idAccessor="name"

                fetching={loading}

                isRecordSelectable = {record => record.status !== false}

                defaultColumnRender={(row, _, accessor) => {
                    const data = row[accessor as keyof typeof row];
                    switch(accessor) {
                        case 'name': return <TableFileName text={data as string} />
                        case 'status': return <TableStatusText status={data as boolean} />
                        case 'messages': return <TableListMessages messages={data as string[]} />
                        case 'loading': return <TableLoadingRow loadingState={data as LoadingState} />
                        default: return (<>{data}</>)
                    }
                }}

                //bodyRef={bodyRef}
            />
        </>
    )

}

export default TableFolders;