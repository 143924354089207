import {LoadingState} from "./LoadingState";
import {TableArticleDetailsRow} from "./tables/TableRows";
import {Box, Flex, Image, Text} from "@mantine/core";
import {DataTableColumn} from "mantine-datatable";
import CategoryColumn from "../components/pageComponents/articoli/tableArticles/categoryColumn/CategoryColumn";
import AvailabilityColumn
    from "../components/pageComponents/articoli/tableArticles/availabilityColumn/AvailabilityColumn";
import ActionColumn from "../components/pageComponents/articoli/tableArticles/actionColumn/ActionColumn";


export interface ImageRow {
    name: string,
    status: boolean,
    messages: string[]
    loading?: LoadingState
}

export const ImageColumns = [
    {accessor: 'name', title: 'Nome', width: '50%', sortable: true},
    {accessor: 'status', title: 'Stato', width: '10%', sortable: true},
    {accessor: 'messages', title: "Messaggio"},
    {accessor: 'loading', title: '', width: '5%'}
]



export const ArticleColumns: DataTableColumn<TableArticleDetailsRow>[] = [
    {
        accessor: 'name',
        title: 'Articolo',
        width: '40%',
        render: (article, index) => (
            <Flex gap={"md"}>
                <Flex align={'center'} justify={'center'} style={{minWidth: '75px', minHeight: '75px', width: '75px'}}>
                    <img style={{maxHeight: '75px', maxWidth: '75px', objectFit: 'cover'}}
                         src={article.name.image as string}/>
                </Flex>
                <Flex align={'center'}>
                    <Text size={'sm'} fw={500}> {article.name.title}</Text>
                </Flex>
            </Flex>
        )
    },
    {
        accessor: 'category',
        title: 'Categoria',
        width: '5%',
        noWrap: false,
        sortable: true,
        render: (article, index) => (
            <CategoryColumn category={article.category} />
        )
    },
    {
        accessor: 'code',
        title: 'Codice',
        width: '10%',
        sortable: true,
        render: (article, index) => (
            <Text size={'xs'}> {article.code}</Text>
        )
    },
    {
        accessor: 'ean',
        title: "EAN",
        width: '10%',
        sortable: true,
        render: (article, index) => (
            <Text size={'xs'}> {article.ean}</Text>
        )
    },
    {
        accessor: 'availability',
        title: "Disponibilità",
        width: '10%',
        sortable: true,
        render: (article, index) => (
            <AvailabilityColumn availability={article.availability} />
        )
    },
    {
        accessor: 'details',
        title: 'Azioni',
        width: '5%',
        render: (article, index) => (
            <ActionColumn article={article.details} />
        )
    }
]

export const MockRecords: ImageRow[] = [
    {name: 'A', status: false, messages: ['C']},
    {name: 'D', status: false, messages: ['F']},
    {name: 'G', status: true, messages: ['I']},
]