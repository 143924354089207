import {createSlice, PayloadAction} from "@reduxjs/toolkit";


const ArticleModalStatusSlice = createSlice({
    name: "articlesModalStatus",
    initialState: "",
    reducers: {
        set: (state, action: PayloadAction<string>) => {
            return action.payload
        }
    }
})



const {reducer, actions} = ArticleModalStatusSlice

export const ArticleModalStatusActions = actions
export default reducer