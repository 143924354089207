import {Page} from "../interfaces/router/Page";
import CaricamentoImmagini from "../pages/main/CaricamentoImmagini";
import CaricamentoCSV from "../pages/main/CaricamentoCSV";
import InserimentoDati from "../pages/main/InserimentoDati";
import Articoli from "../pages/main/articoli/Articoli";


export const privatePages: Page[] = [
    {
        path: "/uploadImages",
        view: CaricamentoImmagini,
        exact: true
    },
    {
        path: "/uploadCsv",
        view: CaricamentoCSV,
        exact: true
    },
    {
        path: "/insertData",
        view: InserimentoDati,
        exact: true
    },
    {
        path: "/articleDetails",
        view: Articoli,
        exact: true
    }

]