import React, {useEffect, useState} from 'react';
import {Button, Container, Flex, Space, Title, useMantineTheme} from '@mantine/core';
import NavBarComponent from "../../components/layoutComponents/navbar/NavbarComponent";
import HeaderComponent from "../../components/Header";
import Shell from "../../components/Shell";
import TableFolders from "../../components/TableFolders";
import {IconFileUpload} from "@tabler/icons-react";
import {ArticleColumns, ImageColumns, ImageRow} from "../../interfaces/ImageTablePage";
import {useLazyGetAllImageFoldersQuery, useUploadImageFoldersMutation} from "../../services/ArticleService";
import ShellLayout from "../../components/layoutComponents/ShellLayout";
import ConnectionStatus from "../../components/layoutComponents/connectionStatus/ConnectionStatus";
import TableArticles from "../../components/pageComponents/articoli/tableArticles/TableArticles";

const CaricamentoImmagini = () =>  {

    const theme = useMantineTheme();
    const [getAllImageFolders, isLoadingGetAllImageFolders, isErrorGetAllImageFolders] = useLazyGetAllImageFoldersQuery();
    const [uploadFolder, isLoading] = useUploadImageFoldersMutation();

    const [folders, setFolders] = useState<ImageRow[]>([])
    const [selectedFolders, setSelectedFolders] = useState<ImageRow[]>([]);


    useEffect(() => {
        getAllImageFolders().then((r) => {
            if(r.data == undefined) {
            }
            else {
                let mappedData: ImageRow[] = []
                r.data.forEach(d => mappedData.push({...d}))
                setFolders(mappedData)
            }

        })
    }, [])

    const handleClick = () => {
        if(selectedFolders.length === 0) {
            return
        }


        selectedFolders.forEach(folder => {
            folder.loading = {loading: true, completed: undefined, error: undefined}
            let i = folders.findIndex(f => f.name === folder.name)
            folders[i] = folder
        })
        setFolders([...folders])

        selectedFolders.forEach(folder => {
            uploadFolder({folders: [folder.name]})
                .unwrap()
                .then(resp => {
                    folder.loading = {loading: undefined, completed: true, error: undefined}
                    let i = folders.findIndex(f => f.name === folder.name)
                    folders[i] = folder
                    setFolders([...folders])
                })
                .catch(err => {
                    folder.loading = {loading: undefined, completed: undefined, error: true}
                    let i = folders.findIndex(f => f.name === folder.name)
                    folders[i] = folder
                    setFolders([...folders])
                })
        })
    }

    return (
        <ShellLayout
            Body={
                <>
                    <Space h={'lg'}></Space>
                    <Container size={'95%'}>
                        <Flex justify={'space-between'}>
                            <Title order={1} fw={500} c={theme.colors.textBlack[0]} mb={'xl'}>Articoli</Title>
                            <ConnectionStatus />
                        </Flex>
                        <Flex style={{paddingBottom: '32px', paddingTop: '16px'}}>
                            <Button
                                radius={'lg'}
                                leftSection={<IconFileUpload/>}
                                onClick={handleClick}
                                style={{backgroundColor: '#aed581', color: 'black', '&:hover': {backgroundColor: '#a1cf6d'}}}>
                                Carica Immagini
                            </Button>
                        </Flex>
                        <TableFolders
                            columns={ImageColumns}
                            rows={folders}
                            loading={isLoadingGetAllImageFolders.isFetching}
                            selected={selectedFolders}
                            setSelected={setSelectedFolders} />
                    </Container>

                </>

            }
        />
    )
}

export default CaricamentoImmagini;