import {AppShell, useMantineTheme} from "@mantine/core";
import React, {useState} from "react";


interface ShellProps {
    navbar: JSX.Element
    body: JSX.Element
}

const Shell = ({navbar, body}: ShellProps) => {
    const theme = useMantineTheme();
    const [openedHeader, setOpenedHeader] = useState(false);
    return (
        <AppShell
            // styles={{
            //     main: {
            //         background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
            //     },
            // }}
        >
            {navbar}
            {body}
        </AppShell>
    );
}

export default Shell;