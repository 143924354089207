import HeaderComponent from "../../components/Header";
import {Button, Flex, Tabs, TextInput} from "@mantine/core";
import {IconFileUpload} from "@tabler/icons-react";
import Shell from "../../components/Shell";
import React, {useState} from "react";
import FormArticleDetails from "../../components/inserimentoDati/FormArticleDetails";
import {createFormContext} from "@mantine/form";
import {articleList} from "../../components/inserimentoDati/ArticleList";
import FormTextEditor from "../../components/pageComponents/inserimentoDati/FormTextEditor";
import {useSelector} from "react-redux";
import {Store} from "../../redux/Store";
import {
    useCreateCsvFileMutation, useGetDescriptionByURLMutation,
    useGetTechnicalDetailsByEANMutation,
    useGetTechnicalDetailsByURLMutation
} from "../../services/ArticleService";
import {CreateCSVRequest} from "../../interfaces/request/CreateCSVRequest";
import {LoadingState} from "../../interfaces/LoadingState";
import TableLoadingRow from "../../components/TableLoadingRow";
import TechnicalDetailsSearch from "../../components/pageComponents/inserimentoDati/TechnicalDetailsSearch";
import FormCheckbox from "../../components/pageComponents/inserimentoDati/FormCheckbox";
import NavBarComponent from "../../components/layoutComponents/navbar/NavbarComponent";



const InserimentoDati = () => {

    // API

    // The POST To send the CSV Data to the Backend
    const [uploadData, isLoading] = useCreateCsvFileMutation();
    const [technicalDetailByEAN, isLoadingTechnicalDetailsByEAN] = useGetTechnicalDetailsByEANMutation();
    const [technicalDetailByURL, isLoadingTechnicalDetailsByURL] = useGetTechnicalDetailsByURLMutation();
    const [descriptionByURL, isLoadingDescriptionByURL] = useGetDescriptionByURLMutation();



    // Regex for the Codice Articolo
    const codiceRegex = /^[A-Z0-9]{8}(?: -.*)?/

    // Form
    const form = useDetailsForm({
        initialValues:
        // Set the initial values for all the input fields. Returns an object {article[i].name: ''}
            articleList.reduce((acc: Record<string, any>, article) => {
                acc.productDetails[article.productType] = {};
                return acc;
            }, {productDetails: {}}),
        validate: {
            Codice: (value: string) => {
                return codiceRegex.test(value) ? null : 'Formato codice non valido'
            }
        }

    });

    // States

    const [loadingState, setLoadingState] = useState<LoadingState>({loading: false, completed: false, error: false})
    const [loadingStateEanSearch, setLoadingStateEanSearch] = useState<LoadingState>({loading: false, completed: false, error: false})
    const [loadingStateUrlSearch, setLoadingStateUrlSearch] = useState<LoadingState>({loading: false, completed: false, error: false})
    const [loadingStateDescriptionUrlSearch, setLoadingStateDescriptionUrlSearch] = useState<LoadingState>({loading: false, completed: false, error: false})
    const [technicalDetailsContent, setTechnicalDetailsContent] = useState<string>("")
    const [descriptionContent, setDescriptionContent] = useState<string>("")

    const activeTab = useSelector((state: Store) => state.selectedProduct);

    // Create CSV
    const handleSubmit = (values: Record<string, any>) => {

        console.log(form.validate())
        setLoadingState({loading: true, completed: false, error: false})
        const { ['productDetails']: _, ...rest} = values
        const cont = {
            ...values['productDetails'][activeTab],
            ...rest
        }

        const request: CreateCSVRequest = {
            content: toCSV(cont),
            code: values['Codice']
        }
        console.log(values['descrizioneHtml'])
        uploadData(request)
            .then( () => setLoadingState({loading: false, completed: true, error: false}))
            .catch( () => setLoadingState({loading: false, completed: false, error: true}))
    }

    // Transform an object into a CSV row
    const toCSV = (content: Record<string, string>): string => {
        const keys = Object.keys(content)
        const values = Object.values(content)

        const header = keys.join(';')
        const row = values.join(';')

        return `${header}\n${row}`
    }

    // EAN Search
    const handleEanClick = (ean: string) => {
        setLoadingStateEanSearch({loading: true, completed: false, error: false})
        technicalDetailByEAN({ean: ean})
            .unwrap()
            .then( (resp) => {
                console.log(resp)
                setTechnicalDetailsContent(resp.technicalDetails)
                setLoadingStateEanSearch({loading: false, completed: true, error: false})
            })
            .catch( () => setLoadingStateEanSearch({loading: false, completed: false, error: true}))
    }

    const handleUrlClick = (url: string) => {
        setLoadingStateUrlSearch({loading: true, completed: false, error: false})
        technicalDetailByURL({url: url})
            .unwrap()
            .then( (resp) => {
                console.log(resp)
                setTechnicalDetailsContent(resp.technicalDetails)
                setLoadingStateUrlSearch({loading: false, completed: true, error: false})
            })
            .catch( () => setLoadingStateUrlSearch({loading: false, completed: false, error: true}))
    }

    const handleUrlDescriptionClick = (url: string) => {
        setLoadingStateDescriptionUrlSearch({loading: true, completed: false, error: false})
        descriptionByURL({url: url})
            .unwrap()
            .then( (resp) => {
                console.log(resp)
                setDescriptionContent(resp.description)
                setLoadingStateDescriptionUrlSearch({loading: false, completed: true, error: false})
            })
            .catch( () => setLoadingStateDescriptionUrlSearch({loading: false, completed: false, error: true}))
    }

    return (
        <Shell
            navbar={<NavBarComponent />}
            body={
                <FormProvider form={form}>
                    <form onSubmit = {form.onSubmit((values) => handleSubmit(values))}>
                        <Flex style={{paddingBottom: '32px', paddingTop: '16px'}} align={'center'}>
                            <Button
                                radius={'lg'}
                                leftSection={<IconFileUpload/>}
                                type="submit"
                                mr='15px'
                                style={{backgroundColor: '#aed581', color: 'black', '&:hover': {backgroundColor: '#a1cf6d'}}}>
                                Crea CSV
                            </Button>
                            <TableLoadingRow loadingState={loadingState} />
                        </Flex>
                        <TextInput
                            mt="md"
                            mb="md"
                            label="Codice"
                            placeholder="00000000"
                            radius={"lg"}
                            error="Formato codice non valido"
                            {...form.getInputProps('Codice')}
                        />
                        <Tabs defaultValue="productDetails" variant="outline" radius="lg">
                            <Tabs.List>
                                <Tabs.Tab p='20px' value="productDetails">Dettagli Articolo</Tabs.Tab>
                                <Tabs.Tab p='20px' value="technical-details">Scheda Tecnica</Tabs.Tab>
                                <Tabs.Tab p='20px' value="description">Descrizione</Tabs.Tab>
                                <Tabs.Tab p='20px' value="otherDetails">Dettagli Articolo</Tabs.Tab>
                            </Tabs.List>


                            <Tabs.Panel maw='1200px' value="productDetails" pt="xs">
                                <FormArticleDetails/>
                            </Tabs.Panel>

                            <Tabs.Panel value="technical-details" pt="xs">
                                <TechnicalDetailsSearch
                                    buttonText="Cerca per URL"
                                    tooltip="Siti supportati: Onlinestore,"
                                    state={loadingStateUrlSearch}
                                    clicked={handleUrlClick}
                                />
                                <TechnicalDetailsSearch
                                    buttonText="Cerca per EAN"
                                    state={loadingStateEanSearch}
                                    clicked={handleEanClick}
                                />
                                <FormTextEditor formName={'Scheda Tecnica'} content={technicalDetailsContent} setContent={setTechnicalDetailsContent}/>
                            </Tabs.Panel>


                            <Tabs.Panel value="description" pt="xs">
                                <TechnicalDetailsSearch
                                    buttonText="Cerca per URL"
                                    tooltip="Siti supportati: Vieffetrade,"
                                    state={loadingStateDescriptionUrlSearch}
                                    clicked={handleUrlDescriptionClick}
                                />
                                <FormTextEditor formName={'descrizioneHtml'} withRichMenu editable content={descriptionContent} setContent={setDescriptionContent}/>
                            </Tabs.Panel>

                            <Tabs.Panel value="otherDetails" pt="xs">
                                <FormCheckbox />
                            </Tabs.Panel>
                        </Tabs>
                    </form>
                </FormProvider>
            }
        />
    )
}

//TODO Put this form in utils as it is a generic form
export const [FormProvider, useDetailsFormContext, useDetailsForm] = createFormContext<Record<string, any>>();
export default InserimentoDati;