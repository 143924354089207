import classes from "./ArticleInfo.module.css";
import {ArticleDetails} from "../../../../../../interfaces/response/ArticleDetails";
import {Box, Flex, Stack} from "@mantine/core";
import FormTextInput from "../../textInput/FormTextInput";
import {useArticleDetailsForm, useArticleDetailsFormContext} from "../../ModalArticle";
import {articleAttributes, colors} from "../../../../../../utils/ArticleUtils";

interface ArticleInfoProps {
    article: ArticleDetails
}

/**
 * Contains the section related to the editable information of the article
 * It dynamically generates form inputs based on the category of the product
 * @param article
 * @constructor
 */
export default function Articleinfo({article}: ArticleInfoProps) {

    return (
        <Box className={classes.root}>
            <Stack gap={'sm'}>
                <Flex gap={'sm'}>
                    {/*General Form Inputs*/}
                    <FormTextInput
                        width={"48.5%"}
                        label={"Marca"}
                        formInfo={{formLabel: "Marca", formContext: useArticleDetailsFormContext}}
                        options={articleAttributes["Marca"]?.options ?? []}
                    />
                    <FormTextInput
                        width={"48.5%"}
                        label={"Classe Energetica"}
                        formInfo={{formLabel: "Classe Energetica", formContext: useArticleDetailsFormContext}}
                        options={articleAttributes["Classe Energetica"]?.options ?? []}/>
                </Flex>
                <Flex gap={'sm'}>
                    <FormTextInput
                        width={"32%"}
                        label={"Altezza (cm)"}
                        formInfo={{formLabel: "Altezza", formContext: useArticleDetailsFormContext}}
                    />
                    <FormTextInput
                        width={"32%"}
                        label={"Larghezza (cm)"}
                        formInfo={{formLabel: "Larghezza", formContext: useArticleDetailsFormContext}}

                    />
                    <FormTextInput
                        width={"32%"}
                        label={"Profondità (cm)"}
                        formInfo={{formLabel: "Profondità", formContext: useArticleDetailsFormContext}}
                    />
                </Flex>
                <Flex gap={'sm'}>
                    <FormTextInput
                        width={"32%"}
                        label={"Colore"}
                        options={colors}
                        formInfo={{formLabel: "Colore", formContext: useArticleDetailsFormContext}}
                    />
                    <FormTextInput
                        width={"32%"}
                        label={"Rumorosità"}
                        formInfo={{formLabel: "Rumorosità", formContext: useArticleDetailsFormContext}}
                    />
                </Flex>
                {/*Specific Form Inputs*/}
                <Flex gap={'sm'} wrap={"wrap"}>
                    {Object.entries(articleAttributes)
                        .filter(([_, details]) => {
                            return (details !== undefined
                                && details.categories !== undefined
                                && (details.categories.includes(article.headerArticleDetails.category)))
                        }).map(([attribute, details], index) => {
                            const label = ('label' in details && details['label']) ? details['label'] : attribute;
                            const formLabel = ('formLabel' in details && details['formLabel']) ? details['formLabel'] : attribute;
                            const options = details['options'] ?? undefined;
                            return (
                                <FormTextInput
                                    key={index}
                                    width={"32%"}
                                    label={label}
                                    options={options}
                                    formInfo={{formLabel: formLabel, formContext: useArticleDetailsFormContext}}/>
                            )
                        })}
                </Flex>
            </Stack>
        </Box>
    )
}