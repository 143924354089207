import {ArticleMappings} from "../../../../../utils/ArticleUtils";
import {Button, Chip} from "@mantine/core";
import {wrap} from "lodash";


interface CategoryLabelProps {
    category: string
}

export default function CategoryColumn({category}: CategoryLabelProps) {

    let mapping = ArticleMappings[category.substring(0, category.length - 3)]
    if (!mapping) {
        mapping = ArticleMappings['DEFAULT']
    }

    return (
        <Button radius={'lg'} color={mapping.color} size={'xs'} variant={'light'} style={{whiteSpace: 'pre-wrap'}}>
            {mapping.name}
        </Button>
    )
}